/**
 * Event Description
 *
 * The name of loaded_checkout_page is ambiguous, In fact, both page cart && page checkout will be triggered.
 * The event name cannot be adjusted and has been written to the db
 * see: https://shopline.atlassian.net/browse/SL-42890
 */
export const LOADED_ANY_PAGE = 'loaded_any_page';
export const LOADED_HOME_PAGE = 'loaded_home_page';
export const ADDED_PRODUCT_TO_CART = 'added_product_to_cart';
export const LOADED_CHECKOUT_PAGE = 'loaded_checkout_page';
export const PLACED_AN_ORDER = 'placed_an_order';
export const SIGNUP_AS_MEMBER = 'signup_as_member';
export const LOADED_CART_PAGE = 'loaded_cart_page';
export const LOADED_JUST_FOR_CHECKOUT_PAGE = 'loaded_just_for_checkout_page';

export const MULTI_AVAILABLE_EVENTS = [
  LOADED_HOME_PAGE,
  ADDED_PRODUCT_TO_CART,
  LOADED_CHECKOUT_PAGE,
  PLACED_AN_ORDER,
  SIGNUP_AS_MEMBER,
];

export const SINGLE_EVENTS = {
  DEFAULT: [
    LOADED_ANY_PAGE,
    LOADED_HOME_PAGE,
    ADDED_PRODUCT_TO_CART,
    LOADED_CHECKOUT_PAGE,
    PLACED_AN_ORDER,
    SIGNUP_AS_MEMBER,
  ],
  GOOGLE_ADS: [
    LOADED_ANY_PAGE,
    LOADED_HOME_PAGE,
    ADDED_PRODUCT_TO_CART,
    LOADED_CART_PAGE,
    LOADED_JUST_FOR_CHECKOUT_PAGE,
    LOADED_CHECKOUT_PAGE,
    PLACED_AN_ORDER,
    SIGNUP_AS_MEMBER,
  ],
};
