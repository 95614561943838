import { META } from 'constants/trackerKinds';

export const fbEntrancePopup = {
  title: `$t(tracker:displayKind, {"context": "${META}"})`,
  content: 'Meta 像素需透過安裝「Facebook 商業擴充套件」取得，是否前往安裝？',
  content_edit:
    '於「Facebook 商業擴充套件」點擊「編輯資產」即可編輯 Facebook 像素，是否前往？',
  content_remove:
    '取消安裝「Facebook 商業擴充套件」即可刪除 Facebook 像素，是否前往取消？',
  ok: '前往安裝',
  ok_edit: '前往編輯',
  ok_remove: '前往取消',
  cancel: '暫時不要',
};
