import * as trackerKinds from 'constants/trackerKinds';
import * as featureKeys from 'constants/featureKeys';

export const main = {
  'App Description':
    '使用第三方追蹤工具如 Google Analytics、Google 代碼管理工具和 Facebook 廣告轉換追蹤可以幫助您追蹤顧客在商店的活動行為。善用追蹤數據進行分析，對於增加網店流量及規劃行銷策略會很有幫助！',
  'Add Tracker': '加入顧客活動追蹤',
  EVENT_TRACKER_TABLE: {
    KIND: '追蹤工具',
    EVENT_TYPES: '追蹤事件',
    [`EVENT_TYPES_${featureKeys.LINE_POINTS}`]: '追蹤事件／活動',
    CODE: '追蹤編號／名稱',
    ACTIONS: '',
  },
  docLink: 'https://support.shoplineapp.com/hc/zh-tw/articles/204215529',
  [`docLink_${trackerKinds.GA}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/204215529',
  [`docLink_${trackerKinds.GTM}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/205276289',
  [`docLink_${trackerKinds.G_ADS}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/205293995',
  [`docLink_${trackerKinds.G_REMARKETING}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/205059449',
  [`docLink_${trackerKinds.BING}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/206305016',
  [`docLink_${trackerKinds.YAHOO}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/206651403',
  [`docLink_${trackerKinds.META}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/360042210731-Facebook-商業擴充功能-商業擴充功能',
  [`docLink_${trackerKinds.LINE}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/6055618128409-LINE-廣告追蹤碼-LAP-設定',
  [`docLink_${trackerKinds.LINE_POINTS}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/13172197373721',
  [`docLink_${trackerKinds.GA4}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/8646049467801',
  docLink_gaECommerce:
    'https://support.shoplineapp.com/hc/zh-tw/articles/207582673',
  docLink_gAdsEnhanced:
    'https://support.google.com/google-ads/answer/9888656?hl=zh-Hant',
  tipsPopover: {
    title: '提示',
    content:
      '了解常見問題：<list><ga>Google Analytics（數據分析）設定方式</ga><gaECommerce>啟動 Google Analytics 的電子商務設定</gaECommerce><gtm>Google 代碼管理工具</gtm><gAds>Google Ads 廣告追蹤碼設定</gAds><gRemarketing>Google 再行銷追蹤碼</gRemarketing><bing>Bing 轉換追蹤</bing><yahoo>Yahoo 原生廣告追蹤碼</yahoo><metaPixel>Meta像素（新）</metaPixel><line>LINE 廣告追蹤碼（LAP）設定</line><ga4>Google Analytics 4（GA4）追蹤碼設定</ga4><linePoints>LINE POINTS 任務廣告（CPA）追蹤設定</linePoints></list>',
    links: {
      ga: `$t(docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(docLink, {"context": "gaECommerce"})',
      gtm: `$t(docLink, {"context": "${trackerKinds.GTM}"})`,
      gAds: `$t(docLink, {"context": "${trackerKinds.G_ADS}"})`,
      gRemarketing: `$t(docLink, {"context": "${trackerKinds.G_REMARKETING}"})`,
      bing: `$t(docLink, {"context": "${trackerKinds.BING}"})`,
      yahoo: `$t(docLink, {"context": "${trackerKinds.YAHOO}"})`,
      metaPixel: `$t(docLink, {"context": "${trackerKinds.META}"})`,
      line: `$t(docLink, {"context": "${trackerKinds.LINE}"})`,
      linePoints: `$t(docLink, {"context": "${trackerKinds.LINE_POINTS}"})`,
      ga4: `$t(docLink, {"context": "${trackerKinds.GA4}"})`,
    },
  },
};
