import * as trackerKinds from 'constants/trackerKinds';
import * as trackerEvents from 'constants/trackerEvents';
import * as trackerConversions from 'constants/trackerConversions';

export const tracker = {
  ID: 'ID',
  [`ID_${trackerKinds.CUSTOM}`]: '自訂追蹤工具名稱',
  displayKind: 'Unknown Tracker',
  [`displayKind_${trackerKinds.BING}`]: 'Bing 關鍵字廣告追蹤碼',
  [`displayKind_${trackerKinds.CRITEO}`]: 'Criteo',
  [`displayKind_${trackerKinds.GA}`]: 'Google Analytics',
  [`displayKind_${trackerKinds.GA4}`]: 'Google Analytics 4',
  [`displayKind_${trackerKinds.G_ADS}`]: 'Google Ads',
  [`displayKind_${trackerKinds.G_REMARKETING}`]: 'Google 再行銷',
  [`displayKind_${trackerKinds.GTM}`]: 'Google 代碼管理工具',
  [`displayKind_${trackerKinds.LINE}`]: 'LINE Tag (LAP)',
  [`displayKind_${trackerKinds.LINE_POINTS}`]: 'LINE POINTS 廣告追蹤碼（CPA）',
  [`displayKind_${trackerKinds.META}`]: 'Meta 像素（新）',
  [`displayKind_${trackerKinds.YAHOO}`]: 'Yahoo! 原生廣告追蹤碼',
  [`displayKind_${trackerKinds.TIKTOK}`]: 'TikTok Pixel',
  [`displayKind_${trackerKinds.CUSTOM}`]: '自訂',
  [`displayKind_${trackerKinds.LEGACY_FB_PIXEL}`]:
    'Facebook 廣告轉換像素（舊）',
  [`displayKind_${trackerKinds.LEGACY_FB_AUDIENCE}`]:
    'Facebook 自訂廣告受眾像素（舊）',
  displayEvent: 'Unknown Event',
  displayEvent_others: '新增其他事件',
  [`displayEvent_${trackerEvents.LOADED_ANY_PAGE}`]: '顧客造訪網店內的任何網頁',
  [`displayEvent_${trackerEvents.LOADED_HOME_PAGE}`]: '顧客造訪網店首頁',
  [`displayEvent_${trackerEvents.ADDED_PRODUCT_TO_CART}`]:
    '顧客將商品加入購物車',
  [`displayEvent_${trackerEvents.LOADED_CHECKOUT_PAGE}`]: '顧客造訪結帳頁面',
  [`displayEvent_${trackerEvents.PLACED_AN_ORDER}`]: '顧客完成下單',
  [`displayEvent_${trackerEvents.SIGNUP_AS_MEMBER}`]: '顧客註冊為會員',
  [`displayEvent_${trackerEvents.LOADED_CART_PAGE}`]: '顧客造訪購物車頁面',
  [`displayEvent_${trackerEvents.LOADED_JUST_FOR_CHECKOUT_PAGE}`]:
    '顧客造訪填寫資料頁面',
  displayEvent_loadedCardOrCheckoutPage: '顧客造訪購物車或結帳頁面',
  html: '自訂事件追蹤 HTML 碼',
  field: {
    trackerKind: '我想使用的追蹤工具是：',
    trackerEvents: '我想追蹤的事件是：',
    code: 'Unknown Code',
    [`code_${trackerKinds.BING}`]: '我的 Bing 廣告轉換像素追蹤編號是：',
    [`code_${trackerKinds.CRITEO}`]: '我的 Criteo ID 是：',
    [`code_${trackerKinds.GA}`]: '我的 Google Analytics 追蹤編號是：',
    [`code_${trackerKinds.GA4}`]: '我的 Google Analytics 4 追蹤編號是：',
    [`code_${trackerKinds.G_ADS}`]: '我的 Google Ads 轉換編號是：',
    [`code_${trackerKinds.G_REMARKETING}`]: '我的 Google 再行銷追蹤編號是：',
    [`code_${trackerKinds.GTM}`]: '我的 Google 代碼管理工具追蹤編號是：',
    [`code_${trackerKinds.LINE}`]: '我的 LINE Ads Tag 轉換編號是：',
    [`code_${trackerKinds.LINE_POINTS}`]: '我的 LINE POINTS Tag 轉換編號是：',
    [`code_${trackerKinds.YAHOO}`]: '我的 Yahoo! 廣告項目編號是：',
    [`code_${trackerKinds.META}`]: '我的 Meta 像素 ID 是：',
    [`code_${trackerKinds.CUSTOM}`]: '我的自訂事件追蹤工具名稱是：',
    [trackerKinds.G_ADS]: {
      isShopline: 'Shopline Ownership Setting',
      code2: '我的 Google Ads 轉換標籤是：',
      enhancedConversions: '是否啟用 Google 轉換追蹤支援「強化轉換」功能',
    },
    [trackerKinds.GA]: {
      enableEcommerce: 'Google Analytics - 電子商務',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'Google Analytics 4 - 加強型評估電子商務事件',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Shopline Ownership Setting',
      dynamic: 'Google 動態再行銷廣告',
      useUniqueId: '使用系統獨立產品編號或商品貨號？',
      singleVariation: '顯示單個商品規格或所有商品規格？',
    },
    [trackerKinds.META]: {
      useUniqueId: '使用系統獨立產品編號或商品貨號？',
      singleVariation: '顯示單個商品規格或所有商品規格？',
    },
    [trackerKinds.YAHOO]: {
      code2: '我的 Yahoo! 廣告追蹤編號是：',
    },
    [trackerKinds.CUSTOM]: {
      html: '我的$t(html)是：',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '我想追蹤的活動是：',
      intercom:
        '若您希望執行 LINE POINTS 廣告，請直接<intercom>聯繫 SHOPLINE 線上顧問</intercom>，我們會派專人為您服務。',
    },
  },
  label: {
    events: '建議設定追蹤事件為：顧客造訪網店內的任何網頁',
    events_disabled: '此追蹤工具已自動協助您埋設/追蹤每個網頁的相對應事件',
    [trackerKinds.G_ADS]: {
      isShopline: 'Set as Read-Only',
      enhancedConversions: '啟用 Google 轉換追蹤支援「強化轉換」',
    },
    [trackerKinds.GA]: {
      enableEcommerce: '啟用電子商務',
    },
    [trackerKinds.GA4]: {
      enhancedEC: '前往 GA4 後台啟用',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Set as Read-Only',
      dynamic: '啟用動態再行銷廣告',
      useUniqueId: '',
      useUniqueId_productId: '使用系統獨立產品編號',
      useUniqueId_sku: '使用商品貨號',
      singleVariation: '',
      singleVariation_showAll: '顯示所有商品規格',
      singleVariation_showOne: '顯示單個商品規格',
    },
    [trackerKinds.META]: {
      useUniqueId: '',
      useUniqueId_productId: '使用系統獨立產品編號',
      useUniqueId_sku: '使用商品貨號',
      singleVariation: '',
      singleVariation_showAll: '顯示所有商品規格',
      singleVariation_showOne: '顯示單個商品規格',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '',
      [`missions_${trackerConversions.PLACED_AN_ORDER}`]: '導購型任務',
    },
  },
  placeholder: {
    trackerEvents: '請選擇',
    code: '',
    [`code_${trackerKinds.BING}`]: '如：1234567',
    [`code_${trackerKinds.CRITEO}`]: '如：123456',
    [`code_${trackerKinds.GA}`]: '如：UA-000000-01',
    [`code_${trackerKinds.GA4}`]: '如：G-XXXXXXX',
    [`code_${trackerKinds.G_ADS}`]: '如：888888888',
    [`code_${trackerKinds.G_REMARKETING}`]: '如：1234567890',
    [`code_${trackerKinds.GTM}`]: '如：GTM-XXXX',
    [`code_${trackerKinds.LINE}`]: '如：888888888',
    [`code_${trackerKinds.LINE_POINTS}`]: '如：FREECOINS_0000',
    [`code_${trackerKinds.YAHOO}`]: '如：12345',
    [`code_${trackerKinds.META}`]: '如：1234567890',
    [trackerKinds.GA4]: {
      enhancedEC:
        '加強型評估可讓您在 Google Analytics 4（分析）介面中啟用選項（事件），評估使用者與您內容的互動情況。啟用後能將網站資料串流相關事件，包含瀏覽事件、網頁垂直捲動事件、外連網域點擊事件、站內搜尋事件、網站內嵌影片參與事件、檔案下載事件',
    },
    [trackerKinds.G_ADS]: {
      code2: '如：aBC_dOe1fghIj2k3lmN',
      enhancedConversions:
        '我們建議您開啟「強化轉換功能」，以利改善 Google Ads 轉換評估準確度。我們協助您利用安全管道將強化轉換資料由伺服器傳送給 Google 端，包括更多網站行為或事件資料，能更深入衡量 Google Ads 廣告成效，讓投放更精準。此外，建議您至「網店設計 > 設計 > 進階設計設定」啟用 Cookies 授權提示功能，以利保護您的網站數據資料傳送。同時非常重要的是，在您選擇第三方服務之前，請諮詢您的法律團隊以確保遵守隱私法規。<br /><lk>瞭解更多</lk>',
    },
    [trackerKinds.YAHOO]: {
      code2: '如：123456',
    },
    [trackerKinds.CUSTOM]: {
      html: '<script></script>',
    },
  },
  fieldValidation: {
    code: {
      pattern: '限輸入半形英文字、數字、- 及 _',
    },
    events: {
      custom: '請至少選擇一項事件',
    },
  },
  link: {
    [trackerKinds.GA4]: {
      enhancedEC:
        'https://support.google.com/analytics/answer/9216061?hl=zh-Hant',
    },
  },
  tipsPopover: {
    title: '提示',
    content:
      '了解常見問題：<list><ga>Google Analytics（數據分析）設定方式</ga><gaECommerce>啟動 Google Analytics 的電子商務設定</gaECommerce></list>',
    links: {
      ga: `$t(main:docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(main:docLink, {"context": "gaECommerce"})',
    },
  },
};
