import { META } from 'constants/trackerKinds';

export const fbEntrancePopup = {
  title: `$t(tracker:displayKind, {"context": "${META}"})`,
  content: 'Meta 像素需透过安装「Facebook 商业扩充套件」取得，是否前往安装？',
  content_edit:
    '于「Facebook 商业扩充套件」点击「编辑资产」即可编辑 Facebook 像素，是否前往？',
  content_remove:
    '取消安装「Facebook 商业扩充套件」即可删除 Facebook 像素，是否前往取消？',
  ok: '前往安装',
  ok_edit: '前往编辑',
  ok_remove: '前往取消',
  cancel: '暂时不要',
};
