import React from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '@shopline/dashboard-ui';

const LoadingWrapper = styled.div<{
  minHeight?: string;
  children?: React.ReactNode;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
`;

export interface ILoadingProps {
  minHeight?: string;
}

const Loading = (props: ILoadingProps) => (
  <LoadingWrapper minHeight={props.minHeight}>
    <LoadingSpinner size={36} />
  </LoadingWrapper>
);

export default Loading;
