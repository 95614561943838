export const auth = {
  page: {
    unAuthorized: {
      title: '抱歉，此页面目前暂时无开放给您！',
      description: '若您仍需进入此页面，请联系您的店长索取权限。',
    },
  },
};

export default auth;
