export const productFeedManager = {
  app: {
    name: '客製化產品目錄',
  },
  tableHeader: {
    channelName: '產品目錄名稱',
    lang: '語言',
    updateTime: '上次更新時間',
  },
  emptyList: {
    title: '客製化您的產品目錄內容，讓您輕鬆管理！',
    description: '設定並新增產品目錄的規則，你可在此管理你的產品目錄',
  },
  title: {
    channelEntry: '新增產品目錄',
  },
  tips: {
    chanelLimit: '已達數量上限',
  },
  tooltip: {
    copyLink: '複製連結',
  },
  popover: {
    productFeedManagerFaq: {
      title: '了解更多',
      content: '各渠道的客製化產品目錄更新時間不盡相同, 更多功能說明請見 FAQ',
      function: '功能說明：',
      link: 'https://support.shoplineapp.com/hc/zh-tw/articles/35139419848217',
    },
  },
  channel: {
    title_GOOGLE: 'Google',
    title_CRITEO: 'Criteo',
  },
  channelListPopup: {
    title: '選擇管道',
    description: '目前每個管道只能新增一個客製化產品目錄，請選擇您要新增的管道',
    description_GOOGLE:
      '支援 Pinterest 及 TikTok 產品目錄，並支援部分 Martech、導購管道使用 (如 Omnichat、圈圈 (Influenxio)、Insider、禾多、Rosetta 等)',
    description_CRITEO: '聯播網再行銷最強利器',
  },
  form: {
    title: '商品上傳規則',
    description: '使用不同條件建立產品目錄的生成規則',
    valid: {
      required: '{{fieldName}} 是必須的',
    },
    fields: {
      title: {
        title: '產品目錄名稱',
        placeholder: '最多輸入 50 字',
      },
      withOutOfStockProduct: {
        title: '選擇商品銷售狀態',
        options: {
          all: '商品有無庫存皆上傳',
          part: '僅上傳有庫存或可銷售的商品',
        },
      },
      withPreorderProduct: {
        title: '加入預購商品',
      },
      categoryIds: {
        title: '指定商品分類 (選填)',
        placeholder: '請選擇',
        selectedNum: '已選（{{num}}）',
      },
      uniqueIds: {
        title: '使用系統獨立產品編號或商品貨號？',
        options: {
          number: '使用商品貨號',
          customNumber: '使用系統獨立產品編號',
        },
      },
      mainProductImageSelect: {
        title: '選擇商品主要圖片 ',
        options: {
          first: '第一張',
          second: '第二張',
          last: '最後一張',
        },
      },
      variantProductImageSelect: {
        title: '選擇商品規格圖檔',
        options: {
          main: '商品主要圖片',
          sku: '商品規格圖片',
        },
      },
      withMpn: {
        title: '是否加入製造編號 (MPN)、商品條碼編號 (Barcode)? ',
      },
      locale: {
        title: '選擇目標語言',
        options: {
          zhHant: '繁體中文',
          zhHans: '简体中文',
          en: 'English',
        },
      },
    },
    footer: {
      save: '儲存',
      description: '最後一步！確認以上內容後點擊儲存按鈕以完成',
    },
    leavePopup: {
      title: '尚未儲存變更',
      description: '如果選擇離開，所做的異動將會流失，建議儲存後再離開。',
    },
  },
  productOverview: {
    title: '商品概覽',
    description:
      '查看符合左側條件將上傳的商品資料，注意，此處僅列出下次更新客製化產品目錄將包含的商品，並不代表產品目錄的即時資料。',
    entry: '查看商品',
    popup: {
      title: '查看商品',
      footer: {
        total: '包含 {{n}} 件商品',
      },
    },
    table: {
      column: {
        name: '商品名稱',
        variation: '商品規格',
      },
    },
    empty: {
      title: '無符合的商品',
      description: '請調整您的商品上傳規則後再試一次',
    },
  },
  deletePopup: {
    title: '刪除產品目錄',
    description:
      '刪除後可能影響使用此產品目錄投放的廣告，商品將從媒體管道中被刪除。',
    confirmDescription: '我確認刪除產品目錄，並了解所帶來的影響',
  },
};

export default productFeedManager;
