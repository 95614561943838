import PropTypes from 'prop-types';
import { TooltipPropType } from './tooltip';
import { LangPropType } from './lang';

export const PopoverPropType = {
  title: PropTypes.node,
  content: PropTypes.node.isRequired,
  direction: TooltipPropType.direction,
  width: PropTypes.number,
  lang: LangPropType,
  withPortal: TooltipPropType.withPortal,
};
