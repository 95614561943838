export const BING = 'bing';
export const CRITEO = 'criteo';
export const GA = 'ga';
export const GA4 = 'ga4';
export const G_ADS = 'gAds';
export const G_REMARKETING = 'gRemarketing';
export const GTM = 'gtm';
export const LINE = 'line';
export const LINE_POINTS = 'linePoints';
export const META = 'meta';
export const TIKTOK = 'tiktok';
export const YAHOO = 'yahoo';

export const CUSTOM = 'customize';

// legacy
export const LEGACY_FB_PIXEL = 'fbPixel';
export const LEGACY_FB_AUDIENCE = 'fbAudience';
