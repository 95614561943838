import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loading } from 'components';
import PostMessageContext from 'contexts/PostMessage';
import { useQueryInitialState } from 'hooks/useInitialState';
import { NotFound } from './NotFound';

const AppBase = ({ children }) => {
  const { i18n } = useTranslation();
  const [{ languageChange }] = useContext(PostMessageContext);
  const { isSuccess } = useQueryInitialState();
  var { merchantId } = useParams();

  useEffect(() => {
    if (languageChange) {
      i18n.changeLanguage(languageChange);
    }
  }, [i18n, languageChange]);

  if (!/[0-9a-f]{24}/.test(merchantId)) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <Loading />;
  }
  return children;
};

export default AppBase;
