import { TIKTOK } from 'constants/trackerKinds';

export const tt4bPopup = {
  title: `$t(tracker:displayKind, {"context": "${TIKTOK}"})`,
  content:
    'TikTok Pixel needs to be obtained by installing "TikTok Business Extension". Go to install it?',
  content_edit:
    'Click "Edit Assets" in "Channel Integration > TikTok" to edit the TikTok pixel. Go to edit?',
  content_remove:
    'Click "Unlink my account" in "Channel Integration > TikTok" to delete the TikTok pixel. Go to unlink?',
  ok: 'Install Now',
  ok_edit: 'Edit Now',
  ok_remove: 'Unlink Now',
  cancel: 'Later',
};
