import axios from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const getInitialState = async (merchantId) => {
  const { data } = await axios.get(
    `/api/initial-state?merchant_id=${merchantId}`,
  );
  return data;
};

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [, merchantId] }) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId) => ['initial-state', merchantId];

export const useQueryInitialState = () => {
  const { merchantId } = useParams();
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getInitialState),
    {
      enabled: !!merchantId,
    },
  );
};
