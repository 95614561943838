export const pageTitle = {
  'Tracker Settings': 'Tracker Settings',
  'Marketing & Tracker': 'Marketing & Tracker',
  'Add Event Tracker': 'Add Event Tracker',
  'Add Event Tracker_edit': 'Edit Event Tracker',
  Apps: 'Apps',
  'Apps Store': 'Apps Store',
};

export default pageTitle;
