export const auth = {
  page: {
    unAuthorized: {
      title: 'You are not authorized to view this page.',
      description:
        'If you wish to access this page, please contact shop admin for permission.',
    },
  },
};

export default auth;
