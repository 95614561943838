import { TIKTOK } from 'constants/trackerKinds';

export const tt4bPopup = {
  title: `$t(tracker:displayKind, {"context": "${TIKTOK}"})`,
  content: 'TikTok Pixel 需透過安裝「TikTok 商業擴充套件」取得，是否前往安裝？',
  content_edit:
    '於「第三方服務 > TikTok」點擊「編輯資產」即可編輯 TikTok 像素，是否前往？',
  content_remove:
    '取消安裝「第三方服務 > TikTok」即可刪除 TikTok 像素，是否前往取消？',
  ok: '前往安裝',
  ok_edit: '前往編輯',
  ok_remove: '前往取消',
  cancel: '暫時不要',
};
