import { META } from 'constants/trackerKinds';

export const fbEntrancePopup = {
  title: `$t(tracker:displayKind, {"context": "${META}"})`,
  content:
    'Meta Pixel needs to be obtained by installing "Facebook Business Extension (FBE)". Go to install it?',
  content_edit:
    'Click "Edit Assets" in "Facebook Business Extension (FBE)" to edit the Facebook pixel. Go to edit?',
  content_remove:
    'Click "Unlink my account" in "Facebook Business Extension (FBE)" to delete the Facebook pixel. Go to unlink?',
  ok: 'Install Now',
  ok_edit: 'Edit Now',
  ok_remove: 'Unlink Now',
  cancel: 'Later',
};
