import React from 'react';
import styled from 'styled-components';
import { Text } from 'components';

interface IProps {
  alt?: string;
  image?: string;
  title?: JSX.Element | string;
  description?: JSX.Element | string;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledTitle = styled(Text)`
  padding-top: 1rem;
`;

const StyledDescription = styled(Text)`
  padding-top: 0.48em;
`;

const BlockMessage: React.FunctionComponent<IProps> = (props) => {
  const { image, title, description, alt } = props;

  return (
    <StyledContent>
      {image && <img alt={alt} src={image} />}
      {title && (
        <StyledTitle fontType="Heading" color="INK_600">
          {title}
        </StyledTitle>
      )}
      {description && (
        <StyledDescription fontType="Body" color="INK_600" weight="Regular">
          {description}
        </StyledDescription>
      )}
    </StyledContent>
  );
};

export default BlockMessage;
