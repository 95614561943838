import * as trackerKinds from 'constants/trackerKinds';
import * as featureKeys from 'constants/featureKeys';

export const main = {
  'App Description':
    "Third party tools such as Google Analytics, Google Tag Manager and Facebook Conversion Pixels can help you track your customers' activities in your store. This collected data can in turn be analyzed to drive traffic and guide marketing campaigns!",
  'Add Tracker': 'Add Tracker',
  EVENT_TRACKER_TABLE: {
    KIND: 'Tracking Service',
    EVENT_TYPES: 'Tracking Event',
    [`EVENT_TYPES_${featureKeys.LINE_POINTS}`]: 'Tracking Event / Campaign',
    CODE: 'Tracking ID Code / Name',
    ACTIONS: '',
  },
  docLink:
    'https://support.shoplineapp.com/hc/en-us/articles/204215529-Google-Analytics-Setup',
  [`docLink_${trackerKinds.GA}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/204215529-Google-Analytics-Setup',
  [`docLink_${trackerKinds.GTM}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205276289-Google-Tag-Manager-Setup',
  [`docLink_${trackerKinds.G_ADS}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205293995-Google-Ads-Conversion-Tracker-Setup',
  [`docLink_${trackerKinds.G_REMARKETING}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205059449-Google-Remarketing-Tag-Setup',
  [`docLink_${trackerKinds.BING}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/206305016-Bing-Ads-Conversion-Tracker-Setup',
  [`docLink_${trackerKinds.YAHOO}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/206651403-Yahoo-Gemini-Native-Ad-Tracking-Code',
  [`docLink_${trackerKinds.META}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/360042210731-Facebook-Business-Extension-',
  [`docLink_${trackerKinds.LINE}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/6055618128409-LINE-Ads-Tag-LAP-Ads-Event-Tracker-Settings',
  [`docLink_${trackerKinds.LINE_POINTS}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/13172197373721',
  [`docLink_${trackerKinds.GA4}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/8646049467801',
  docLink_gaECommerce:
    'https://support.shoplineapp.com/hc/en-us/articles/207582673-Google-Analytics-Ecommerce-Setup',
  docLink_gAdsEnhanced:
    'https://support.google.com/google-ads/answer/9888656?hl=en',
  tipsPopover: {
    title: 'Hint',
    content:
      'Learn about frequently asked questions:<list><ga>Google Analytics Setup</ga><gaECommerce>Google Analytics Ecommerce Setup</gaECommerce><gtm>Google Tag Manager</gtm><gAds>Google Ads Tracking Code Setup</gAds><gRemarketing>Google Remarketing Tag</gRemarketing><bing>Bing Ads Conversion Tracking Setup</bing><yahoo>Yahoo! Gemini (Native Ad) Tracking Code</yahoo><metaPixel>Meta Pixel (New)</metaPixel><line>LINE Ads Tag (LAP Ads) Event Tracker Settings</line><ga4>Google Analytics 4 (GA4) Event Tracker Setting</ga4><linePoints>LINE POINTS Tag (CPA) Tracker Settings</linePoints></list>',
    links: {
      ga: `$t(docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(docLink, {"context": "gaECommerce"})',
      gtm: `$t(docLink, {"context": "${trackerKinds.GTM}"})`,
      gAds: `$t(docLink, {"context": "${trackerKinds.G_ADS}"})`,
      gRemarketing: `$t(docLink, {"context": "${trackerKinds.G_REMARKETING}"})`,
      bing: `$t(docLink, {"context": "${trackerKinds.BING}"})`,
      yahoo: `$t(docLink, {"context": "${trackerKinds.YAHOO}"})`,
      metaPixel: `$t(docLink, {"context": "${trackerKinds.META}"})`,
      line: `$t(docLink, {"context": "${trackerKinds.LINE}"})`,
      linePoints: `$t(docLink, {"context": "${trackerKinds.LINE_POINTS}"})`,
      ga4: `$t(docLink, {"context": "${trackerKinds.GA4}"})`,
    },
  },
};

export default main;
