import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Popover as BasePopover, Icons } from '@shopline/dashboard-ui';
import { PopoverPropType } from 'types/popover';
import { withProps } from 'utils/recompose';

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
`;

const MediumQuestionMark = withProps({ size: 'MEDIUM' })(
  Icons.Outline.QuestionMark,
);

export const Popover = (props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <BasePopover lang={language} {...props} titleIcon={MediumQuestionMark}>
      <IconWrapper>
        <MediumQuestionMark />
      </IconWrapper>
    </BasePopover>
  );
};
Popover.propTypes = PopoverPropType;
