import { TIKTOK } from 'constants/trackerKinds';

export const tt4bPopup = {
  title: `$t(tracker:displayKind, {"context": "${TIKTOK}"})`,
  content: 'TikTok Pixel 需透过安装「TikTok 商业扩充套件」取得，是否前往安装？',
  content_edit:
    '于「第三方服务 > TikTok」点击「编辑资产」即可编辑 TikTok 像素，是否前往？',
  content_remove:
    '取消安装「第三方服务 > TikTok」即可删除 TikTok 像素，是否前往取消？',
  ok: '前往安装',
  ok_edit: '前往编辑',
  ok_remove: '前往取消',
  cancel: '暂时不要',
};
