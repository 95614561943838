import React from 'react';
import { Loading } from 'components';
import { ILoadingProps } from 'components/Loading';

type TWithLoading = <T>(Component) => (
  props: {
    loadingProps: {
      loading?: boolean;
    } & ILoadingProps;
  } & T,
) => any;

const withLoading: TWithLoading =
  (Component) =>
  ({ loadingProps, ...props }) => {
    const { loading, ...loadingComponentProps } = loadingProps;
    return loading ? (
      <Loading {...loadingComponentProps} />
    ) : (
      <Component {...props} />
    );
  };

export default withLoading;
