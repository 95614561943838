export const auth = {
  page: {
    unAuthorized: {
      title: '抱歉，此頁面目前暫時無開放給您！',
      description: '若您仍需進入此頁面，請聯繫您的店長索取權限。',
    },
  },
};

export default auth;
