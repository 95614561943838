import * as trackerKinds from 'constants/trackerKinds';
import * as featureKeys from 'constants/featureKeys';

export const main = {
  'App Description':
    '使用第三方追踪工具如 Google Analytics、Google代码管理工具和 Facebook 广告转换追踪可以帮助您追踪顾客在商店的活动行为。善用追踪数据进行分析，对于增加网店流量及规划营销策略会很有帮助！',
  'Add Tracker': '加入顾客活动追踪',
  EVENT_TRACKER_TABLE: {
    KIND: '追踪工具',
    EVENT_TYPES: '追踪事件',
    [`EVENT_TYPES_${featureKeys.LINE_POINTS}`]: '追踪事件／活动',
    CODE: '追踪编号／名称',
    ACTIONS: '',
  },
  docLink:
    'https://support.shoplineapp.com/hc/zh-cn/articles/204215529-Google-Analytics-GA-设置方式',
  [`docLink_${trackerKinds.GA}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/204215529-Google-Analytics-GA-设置方式',
  [`docLink_${trackerKinds.GTM}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/205276289-Google-踪代码管理器-GTM-安裝方式',
  [`docLink_${trackerKinds.G_ADS}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/205293995-Google-Ads-转化跟踪安装方式',
  [`docLink_${trackerKinds.G_REMARKETING}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/205059449-Google-Ads-再营销跟踪码安装方式',
  [`docLink_${trackerKinds.BING}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/206305016-Bing-转换追踪安装方式',
  [`docLink_${trackerKinds.YAHOO}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/206651403-Yahoo-原生广告追踪码',
  [`docLink_${trackerKinds.META}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/360042210731-Facebook-商业扩展程序-商业扩展程序',
  [`docLink_${trackerKinds.LINE}`]:
    'https://support.shoplineapp.com/hc/zh-tw/articles/6055618128409-LINE-廣告追蹤碼-LAP-設定',
  [`docLink_${trackerKinds.LINE_POINTS}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/13172197373721',
  [`docLink_${trackerKinds.GA4}`]:
    'https://support.shoplineapp.com/hc/zh-cn/articles/8646049467801',
  docLink_gaECommerce:
    'https://support.shoplineapp.com/hc/zh-cn/articles/207582673-启动-Google-Analytics-电子商务功能',
  docLink_gAdsEnhanced:
    'https://support.google.com/google-ads/answer/9888656?hl=zh-Hans',
  tipsPopover: {
    title: '提示',
    content:
      '了解常见问题：<list><ga>Google Analytics（数据分析）设定方式</ga><gaECommerce>启动 Google Analytics 的电子商务设定</gaECommerce><gtm>Google 代码管理工具</gtm><gAds>Google Ads 广告追踪码设定</gAds><gRemarketing>Google 再营销追踪码</gRemarketing><bing>Bing 转换追踪</bing><yahoo>Yahoo! 原生广告追踪码</yahoo><metaPixel>Meta像素（新）</metaPixel><line>LINE 广告追踪码（LAP）设定</line><ga4>Google Analytics 4（GA4）追踪码设定</ga4><linePoints>LINE POINTS 任务广告（CPA）追踪设定</linePoints></list>',
    links: {
      ga: `$t(docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(docLink, {"context": "gaECommerce"})',
      gtm: `$t(docLink, {"context": "${trackerKinds.GTM}"})`,
      gAds: `$t(docLink, {"context": "${trackerKinds.G_ADS}"})`,
      gRemarketing: `$t(docLink, {"context": "${trackerKinds.G_REMARKETING}"})`,
      bing: `$t(docLink, {"context": "${trackerKinds.BING}"})`,
      yahoo: `$t(docLink, {"context": "${trackerKinds.YAHOO}"})`,
      metaPixel: `$t(docLink, {"context": "${trackerKinds.META}"})`,
      line: `$t(docLink, {"context": "${trackerKinds.LINE}"})`,
      linePoints: `$t(docLink, {"context": "${trackerKinds.LINE_POINTS}"})`,
      ga4: `$t(docLink, {"context": "${trackerKinds.GA4}"})`,
    },
  },
};
