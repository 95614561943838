import { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Loading } from 'components';
import ToastList from './ToastList';
import AppBase from './AppBase';
import NotFound from './NotFound';

const MainComponent = lazy(() => import('./Main'));
const TrackerEditorComponent = lazy(() => import('./TrackerEditor'));
const ProductFeedManagerListComponent = lazy(() =>
  import('./ProductFeedManagerList'),
);
const ProductFeedManagerEditComponent = lazy(() =>
  import('./ProductFeedManagerEdit'),
);

const FeatureRoute = () => (
  <Router>
    <Switch>
      <Route path="/:merchantId">
        <AppBase>
          <Switch>
            <Route
              exact
              path="/:merchantId"
              render={(props) => (
                <Redirect to={`/${props.match.params.merchantId}/trackers`} />
              )}
            />
            <Route
              exact
              path="/:merchantId/trackers"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <MainComponent />
                </Suspense>
              )}
            />
            <Route
              path="/:merchantId/trackers/create"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <TrackerEditorComponent />
                </Suspense>
              )}
            />
            <Route
              path="/:merchantId/trackers/:trackerId"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <TrackerEditorComponent />
                </Suspense>
              )}
            />
            <Route
              path="/:merchantId/trackers/:trackerId/edit"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <TrackerEditorComponent />
                </Suspense>
              )}
            />
            <Route
              path="/:merchantId/product-feed-manager/list"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <ProductFeedManagerListComponent />
                </Suspense>
              )}
            />
            <Route
              path="/:merchantId/product-feed-manager/:actionType/:id"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <ProductFeedManagerEditComponent />
                </Suspense>
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </AppBase>
      </Route>
      <Route component={NotFound} />
    </Switch>
    <Route component={ToastList} />
  </Router>
);

export default FeatureRoute;
