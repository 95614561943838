import React from 'react';
import { Text } from 'components';
import { BlockMessagePage as BlockMessagePageComponent } from '@shopline/dashboard-ui';

interface IProps {
  alt?: string;
  image: string;
  title?: string;
  description?: string;
}

const BlockMessagePage: React.FunctionComponent<IProps> = (props) => {
  const { alt, image, title, description } = props;
  return (
    <BlockMessagePageComponent
      alt={alt}
      image={image}
      title={
        <Text fontType="Heading" color="INK_600">
          {title}
        </Text>
      }
      description={
        description ? (
          <Text fontType="Body" color="INK_600">
            {description}
          </Text>
        ) : (
          ''
        )
      }
    />
  );
};

export default BlockMessagePage;
