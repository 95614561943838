import { useMemo, useContext, useCallback, useEffect } from 'react';
import { media, Toast as UIToast } from '@shopline/dashboard-ui';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ActionsContext, { StateContext } from 'contexts/Toast';

const ToastContainer = styled.div`
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 50;
  ${media.mobile`
    left: 0;
    right: 0;
    top: 1.5rem;
  `}
`;

const toastMap = {
  alert: UIToast.Alert,
  information: UIToast.Information,
  success: UIToast.Success,
  warning: UIToast.Warning,
};

const useDuration = ({ duration, dismiss }) => {
  useEffect(() => {
    if (duration) {
      setTimeout(dismiss, duration);
    }
  }, [duration, dismiss]);
};

const ToastItem = ({
  as: Component,
  id,
  isShow,
  duration,
  context,
  title,
  titleWithParams,
  description,
  descriptionWithParams,
  descriptionNodeWithProps,
}) => {
  const { t } = useTranslation();
  const { dismissUIToast, deleteUIToast } = useContext(ActionsContext);

  const memoizedTitle = useMemo(() => {
    if (titleWithParams) {
      const { key, params } = titleWithParams;
      return t(key, { ...params });
    }
    if (title) {
      return t(title);
    }
    return '';
  }, [t, titleWithParams, title]);

  const memoizedDescription = useMemo(() => {
    if (descriptionNodeWithProps) {
      const { node: Description, props = {} } = descriptionNodeWithProps;
      return <Description {...props} />;
    }
    if (descriptionWithParams) {
      const { key, params } = descriptionWithParams;
      return t(key, { ...params, context });
    }
    if (description) return t(description, { context });
    return undefined;
  }, [
    t,
    descriptionNodeWithProps,
    descriptionWithParams,
    description,
    context,
  ]);

  const handleAnimationEnd = useCallback(() => {
    if (!isShow) {
      deleteUIToast(id);
    }
  }, [isShow, id, deleteUIToast]);
  const handleClick = useCallback(
    () => dismissUIToast(id),
    [dismissUIToast, id],
  );

  useDuration({ duration, dismiss: handleClick });

  return (
    <Component
      e2eId={`toast-${id}`}
      title={memoizedTitle}
      description={memoizedDescription}
      isShow={isShow}
      onClick={handleClick}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

const ToastList = () => {
  const toasts = useContext(StateContext);

  const hasToast = useMemo(() => Object.keys(toasts).length > 0, [toasts]);

  if (!hasToast) return null;

  return (
    <ToastContainer>
      {Object.values(toasts).map((toast) => {
        const Toast = toastMap[toast.type];
        if (!Toast) return null;
        return <ToastItem as={Toast} key={toast.id} {...toast} />;
      })}
    </ToastContainer>
  );
};

export default ToastList;
