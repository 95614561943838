export const common = {
  Test: 'Test',
  Save: '儲存',
  Add: '新增',
  Edit: '編輯',
  View: '檢視',
  Return: '返回',
  Cancel: '捨棄',
  Cancel2: '取消',
  Cancel_return: '$t(Return)',
  Remove: '刪除',
  Contact: '聯繫顧問',
  Delete: '刪除',
  Leave: '離開',
  Confirm: '確認',
};
