import PropTypes from 'prop-types';
import { LangPropType } from './lang';

export const TOOLTIP_POSITIONS = {
  TOP_RIGHT: 'TOP_RIGHT',
  TOP_CENTER: 'TOP_CENTER',
  TOP_LEFT: 'TOP_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM_CENTER: 'BOTTOM_CENTER',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
};

export const TooltipPropType = {
  direction: PropTypes.oneOf(Object.values(TOOLTIP_POSITIONS)).isRequired,
  text: PropTypes.string,
  lang: LangPropType,
  positionLeft: PropTypes.string,
  positionRight: PropTypes.string,
  positionTransform: PropTypes.string,
  triggerType: PropTypes.oneOf(['hover', 'click']),
  overlay: PropTypes.node,
  isArrowHide: PropTypes.bool,
  delay: PropTypes.number,
  e2eTooltipId: PropTypes.string,
  e2eContentId: PropTypes.string,
  withPortal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
